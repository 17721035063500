@import "_variables";

.accordion-button {
  margin-top: 5px;
  background-color: $primary !important;
  color: white !important;
  font-size: 1.8rem !important;
}
.accordion-button-red {
  background-color: $primary !important;
}
.accordion-button-grey {
  background-color: $grey !important;
}

@media screen and (min-width: 768px) {
  .accordion-body {
    font-size: 2rem !important;
  }
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
@media screen and (max-width: 768px) {
  .accordion-body {
    font-size: 1.4rem !important;
  }
  .accordion-button {
    font-size: 1.5rem !important;
  }
}

.accordion-body {
  text-align: left;
}
