@import "_variables";

.header_navbar {
  background-color: $bg-grey;
  padding-bottom: 4rem;
}
.logo {
  width: 10rem;
}

.nav-div {
  ul {
    li {
      list-style-type: none;
      a {
        text-decoration: none;
        color: $black;
        font-family: $poppins;
        font-size: 1.8rem;

        &:hover {
          color: $primary;
          text-decoration: underline;
        }
        &:active {
          color: $primary;
        }
      }
    }
  }
}

.navbar {
  align-items: end !important;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

header {
  position: sticky;
  top: 0;
  z-index: 99;
}
.mr-nav {
  margin-right: 3rem;
}

@media screen and (max-width: 838px) {
  .nav-div {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .display-none-responsive {
    display: none !important;
  }
}
