@import "_variables.scss";

/* thm-header start */

.thm-header::before {
  position: absolute;
  width: 2px;
  height: 16px;
  background: currentColor;
  z-index: 1;

  top: 0;
  left: 0;
}

.thm-header::after {
  position: absolute;
  width: 4px;
  height: 2px;
  background: currentColor;
  top: 16px;
  left: 0;
  z-index: 2;
}

.thm-header.text-center::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.thm-header.text-center::after {
  left: calc(50% + 1px);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}

.thm-header h1 {
  font-weight: 200;
  font-size: 60px;
}

.thm-header p,
.thm-header li,
.thm-header li a {
  font-size: 11px;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 600;
}

.thm-header li {
  position: relative;
  display: inline-block;
  margin-left: 7px;
}

.thm-header li:not(:last-of-type) {
  padding-right: 13px;
}

.thm-header li:not(:last-of-type)::after {
  position: absolute;
  content: "-";
  color: $primary;
  right: -3px;
}

.op-header {
  padding: 120px 0 106px;
  background: #f3f5f9;
}
/* thm-header end */

input,
textarea {
  border: 0;
  outline: 0;
  box-shadow: none;
  resize: none;
  background-color: transparent;
}

input:focus,
textarea:focus {
  box-shadow: none;
  border: 0;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

textarea::-moz-placeholder,
input::-moz-placeholder {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

textarea::placeholder,
input::placeholder {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.contact-form form input {
  width: calc(50% - 10px);
  height: 71px;
}

.contact-form form input {
  width: calc(50% - 10px) !important;
  height: 71px;
}

.contact-form form textarea {
  width: 100%;
  height: 188px;
  padding-top: 26px;
}

.contact-form form button {
  display: inline-block;
  padding: 15px 38px 15px 40px;
  background: $primary;
  border-radius: 25px;
  margin-bottom: 50px;
  color: #fff;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.contact-form form button:hover {
  background: #ca1f26;
  color: #fff;
}
/* contact end */

.contact-form form input,
.contact-form form textarea {
  padding-left: 30px;
  margin-bottom: 20px;
  background: #e7e9ef;
  font-size: 13px;
  color: #05223a;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}
.Toastify__toast-body > div:last-child {
  font-size: 1.5rem;
}

.home-filter-button:hover {
  background: #ca1f26 !important;
  color: #fff !important;
}

code {
  font-size: 1em !important;
  color: $primary !important;
  word-wrap: break-word !important;
  font-family: $poppins !important;
}

@media screen and (max-width: 1000px) {
  .contact-form form input {
    width: calc(100%) !important;
    height: 50px;
  }
}
