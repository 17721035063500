@import "_variables";

.about-head {
  color: $primary;
}

.about-font {
  font-size: 1.8rem;
  font-family: $poppins;
  text-align: justify;
}

.osi_logo_about {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 3em;
  margin-bottom: 3em;
  opacity: 0.1;
}

@media (max-width: 768px) {
  .about-font {
    font-size: 1.6rem;
  }
}
