@import "_variables";

.homerow {
  margin-top: 50px !important;
}

.form-select {
  border: 1px solid #840812 !important;
  color: #840812 !important;
}

.form-select-lg {
  font-size: 2rem !important;
  margin-top: 3rem;
}
.btn {
  background-color: $primary !important;
  border: none;
  color: white !important;
  padding: 15px 32px !important;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-top: 3rem !important;
  cursor: pointer;
  margin-right: auto !important;
  border-radius: 15px !important;
}
.nav {
  justify-content: center;
}

.nav-tabs {
  border-bottom: 1px solid #840812 !important;

  .nav-link {
    font-size: 2rem;
    font-family: $poppins;
    color: $black;
    width: 300px;
    border-radius: 25px !important;

    &.active {
      color: $white !important;
      background-color: $primary !important;
    }
    &:hover {
      color: $primary;
      border-color: rgba(132, 8, 18, 0.2) rgba(132, 8, 18, 0.2)
        rgba(132, 8, 18, 0.2) !important;
    }
  }
}
table {
  thead {
    th {
      font-size: 1.5rem;
      font-weight: 600;
      padding-top: 2rem !important;
      padding-bottom: 2rem !important;
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: rgba(0, 0, 0, 0.327);
      color: white;
      // border-left: 1px solid !important;
      // border-right: 1px solid !important;
      // border-bottom: 1px solid !important;
      // border-top: 1px solid !important;
    }
  }
}

table {
  tbody {
    tr {
      td {
        font-size: 1.5rem !important;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    border-bottom: 1px solid #840812 !important;

    .nav-link {
      font-size: 1.7rem;
      font-family: $poppins;
      color: $black;
      width: 160px;
      border-radius: 25px !important;
      border-color: rgba(132, 8, 18, 0.2) rgba(132, 8, 18, 0.2)
        rgba(132, 8, 18, 0.2) !important;

      &.active {
        color: $white !important;
        background-color: $primary !important;
      }
      &:hover {
        color: $primary;
        border-color: rgba(132, 8, 18, 0.2) rgba(132, 8, 18, 0.2)
          rgba(132, 8, 18, 0.2) !important;
      }
    }
  }
}
