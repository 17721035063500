@import "_variables";

html {
  font-size: 62.5%;
}
body {
  font-size: 1.6rem;
  position: relative;
  font-family: $poppins;
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}
body::after {
  content: "";
  display: block;
}
