footer {
  position: relative;
  z-index: 99;
  top: 100%;
  left: 0;
  padding: 5rem 0rem 5rem 0rem;
  background-color: $bg-grey;
  width: 100%;
}

.footer_baslik {
  font-size: 2rem !important;
  margin-bottom: 2rem;
  color: $primary;
}

.footer_list {
  list-style-type: none !important;
  padding: 0;
  li {
    font-size: 1.5rem;
    cursor: pointer;
    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }
}

.sol_bosluk {
  padding-left: 10rem !important;
}

.footer-info-text {
  font-size: 1.5rem;
}

.designed-by-osi {
  cursor: pointer;
  max-width: 150px;
}

div ul li a,
.footer-info-osi {
  color: black !important;
  text-decoration: none !important;

  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.osi-logo {
  max-width: 150px !important;
}

.download-button {
  max-width: 16rem;
  cursor: pointer;
  margin-bottom: 10px;
}
