#App {
  font-family: sans-serif;
}

#page-wrap {
  text-align: center;
}

.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
}

.bm-item:hover {
  color: white;
  text-decoration: underline;
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #840812;
  padding: 2.5em 1.5em 0;
  font-size: 1em;
  line-height: 6rem;
  letter-spacing: 0.5px;
}

@media screen and (max-width: 768px) {
  .bm-menu {
    background: linear-gradient(to top, rgba(255, 255, 255, 1), #840812),
      linear-gradient(to right, #840812, white);
    padding: 2.5em 1.5em 0;
    font-size: 1em;
    line-height: 5rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}
